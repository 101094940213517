.scroll-container::-webkit-scrollbar {
  @apply w-16;
}

.scroll-container::-webkit-scrollbar-thumb {
  @apply bg-greyblue-70 rounded-full border-4 border-solid border-transparent bg-clip-content;
}

.scroll-container::-webkit-scrollbar-track {
  @apply bg-greyblue-95 border-solid border border-greyblue-85 rounded-full;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  @apply bg-white;
}
