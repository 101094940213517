.dialog-overlay {
  @apply fixed inset-0 animate-modal-overlay-show bg-black/80;
}

.dialog-content-wrapper {
  @apply rounded-8 fixed top-1/2 left-1/2 w-[90vw] max-w-6xl -translate-x-1/2 -translate-y-1/2 animate-modal-content-show;

  &[data-size='small'] {
    @apply max-w-4xl;
  }

  &[data-theme='dark'] {
    @apply p-1;

    background: linear-gradient(to bottom, rgb(255 255 255 / 10%) 0%, rgb(255 255 255/6%) 20%, transparent 100%),
      linear-gradient(to bottom, rgb(208 208 214 / 10%) 0%, rgb(208 208 214 / 10%) 100%);
    box-shadow:
      0 0 6px 0 rgb(0 0 0 / 21%),
      0 6px 6px 0 rgb(0 0 0 / 12%);
  }

  &[data-theme='light'] {
    @apply bg-greyblue-5 subpixel-antialiased;
  }
}

.dialog-close-button {
  @apply absolute top-16 right-16;

  &[data-theme='dark'] {
    @apply text-white;
  }

  &[data-theme='light'] {
    @apply text-black;
  }
}
