/* stylelint-disable no-descending-specificity */

.root {
  @apply relative h-24 w-48 rounded-full border;

  .thumb {
    @apply block h-16 w-16 translate-x-4 rounded-full transition-transform will-change-transform data-[state=checked]:translate-x-[26px];
  }

  &:hover {
    .thumb {
      @apply bg-greyblue-5 from-gradient-end to-gradient-start;
    }
  }
}

.thumb {
  &[data-state='checked'] {
    @apply bg-gradient-to-br from-gradient-start to-gradient-end;
  }
}

.theme-dark {
  @apply border-greyblue-70 bg-greyblue-85 hover:border-greyblue-40;

  .thumb {
    @apply bg-greyblue-20;
  }

  &[data-state='checked'] {
    @apply border-greyblue-40;
  }
}

.theme-light {
  @apply border-greyblue-20;

  .thumb {
    @apply bg-greyblue-40;
  }

  &:hover {
    .thumb {
      @apply bg-greyblue-20;
    }
  }
}

.theme-dark + label {
  @apply text-white;
}

.theme-light + label {
  @apply text-greyblue-95;
}

/* stylelint-enable no-descending-specificity */
