.trigger-inner {
  @apply flex w-full items-center justify-between gap-x-12 rounded-4  px-16 py-12 text-b14;
}

.trigger {
  /* Using a slightly larger rounded-value on .trigger than .trigger-inner results in a better border. */
  @apply w-full max-w-[320px] rounded-[5px] p-1;

  &[data-state='open'] {
    @apply bg-gradient-to-br from-gradient-start to-gradient-end;
  }

  &[data-theme='dark'] {
    @apply bg-greyblue-70 antialiased text-white;

    .trigger-inner {
      @apply bg-greyblue-85;
    }

    .placeholder {
      @apply text-greyblue-40;
    }

    &[disabled] {
      @apply text-greyblue-40 bg-greyblue-70 opacity-40;
    }
  }

  &[data-theme='light'] {
    @apply bg-greyblue-20;

    .trigger-inner {
      @apply bg-white text-greyblue-95;
    }

    .placeholder {
      @apply text-greyblue-55;
    }
  }
}

.item {
  @apply cursor-pointer py-8;
}

.scroll-button {
  @apply flex justify-center absolute left-[1px] right-[1px] z-10 items-center;
}

.scroll-button-up {
  @apply top-[1px] rounded-t-4 pt-[6px] pb-4;
}

.scroll-button-down {
  @apply bottom-[1px] rounded-b-4 pt-4 pb-[6px];
}

.content {
  @apply z-30 rounded-4 border px-16 py-8 text-b14;

  width: var(--radix-popper-anchor-width);
  max-height: calc(var(--radix-select-content-available-height) - 40px);

  &[data-theme='dark'] {
    @apply border-greyblue-70 bg-greyblue-85 text-white antialiased;

    .item {
      @apply text-greyblue-40 hover:text-white;
    }

    .scroll-button {
      @apply bg-greyblue-85;
    }
  }

  &[data-theme='light'] {
    @apply border-greyblue-20 bg-white text-greyblue-95;

    .item {
      @apply text-greyblue-55 hover:text-greyblue-95;
    }

    .scroll-button {
      @apply bg-white;
    }
  }
}

.viewport {
  @apply flex flex-col;
}
