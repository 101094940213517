.notification-bar {
  @apply relative w-full p-8 text-center text-14 text-white;

  background-image: linear-gradient(99deg, #00aded 5%, #6468e7 50%, #6468e7 100%);
  background-position-x: 10%;
  background-size: 130%;
  transition: background-position 250ms ease-in-out;

  &:hover {
    background-position-x: 100%;
  }
}
