.slider-root {
  @apply relative flex shrink-0 items-center w-full h-20 select-none touch-none;
}

.slider-track {
  @apply bg-greyblue-85 border border-greyblue-70 h-[6px] relative rounded-full grow;
}

.slider-thumb {
  @apply bg-greyblue-5 block w-20 h-20 rounded-full;

  &:hover {
    @apply bg-white;
  }

  :focus {
    @apply outline-none;
  }
}
