.item {
  @apply bg-greyblue-85 rounded-4 px-12 py-8 flex gap-16 items-center cursor-pointer;
}

.option {
  @apply mb-8;

  /* &[data-highlighted] {
  } */
}

.trigger {
  @apply rounded-4 bg-greyblue-70 p-1;

  &[data-state='open'] {
    @apply bg-gradient-to-br from-gradient-start to-gradient-end;
  }
}

.trigger-inner {
  @apply flex w-full items-center justify-between gap-x-12 rounded-4 bg-greyblue-85 px-16 py-12 text-b14 text-white;
}
