.cell {
  @apply self-start;

  &:nth-child(1) {
    @apply col-span-2 pl-24;
  }

  &:nth-child(2) {
    @apply col-span-4;
  }

  &:nth-child(3) {
    @apply col-span-5;
  }

  &:nth-child(4) {
    @apply col-span-5;
  }

  &:nth-child(5) {
    @apply col-span-4;
  }

  &:nth-child(6) {
    @apply col-span-4 pl-0 flex pr-32;
  }
}
