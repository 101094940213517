.tooltip {
  @apply rounded-8 bg-white px-24 py-16;
}

.chart {
  width: 100%;

  :global(.grid):last-child {
    stroke-opacity: 0;
  }

  /* class name comes from recharts */
  /* stylelint-disable-next-line selector-class-pattern */
  :global(.xAxis) {
    @apply text-b12;
  }

  :global(.recharts-tooltip-wrapper-left) .tooltip {
    @apply relative;

    &::after {
      @apply absolute top-1/2 right-[-9px] w-0 h-0 border-t-[10px] border-b-[10px] border-l-[10px] border-transparent border-l-white transform -translate-y-1/2;

      content: '';
    }
  }

  :global(.recharts-tooltip-wrapper-right) .tooltip {
    @apply relative;

    &::after {
      @apply absolute top-1/2 left-[-9px] w-0 h-0 border-t-[10px] border-b-[10px] border-r-[10px] border-transparent border-r-white transform -translate-y-1/2;

      content: '';
    }
  }
}
