.cell {
  &:nth-child(1) {
    @apply col-span-8 pl-24;
  }

  &:nth-child(2) {
    @apply col-span-10;
  }

  &:nth-child(3) {
    @apply col-span-4;
  }

  &:nth-child(4) {
    @apply col-span-2 pl-0 flex justify-end pr-24;
  }
}
