/* basic */
.button {
  @apply text-b14 text-black inline-flex items-center justify-center relative isolate px-20 transition-colors disabled:opacity-20 disabled:pointer-events-none;
}

/* constrained */
.has-fixed-aspect-ratio {
  @apply aspect-square px-0;
}

/* 
 * button size
 * 
 * We had to introduce the `button-`-prefix because `size-x` is a new valid tailwind css class.
 */
.button-size-24 {
  @apply min-h-[24px];
}

.button-size-34 {
  @apply min-h-[34px];
}

.button-size-40 {
  @apply min-h-[40px];
}

.button-size-44 {
  @apply min-h-[44px];
}

.button-size-48 {
  @apply min-h-[48px];
}

.button-size-56 {
  @apply min-h-[56px] text-b16 px-32;
}

/* appearance */
.highlight {
  @apply bg-gradient-to-br from-gradient-start to-gradient-end text-white;

  &:hover {
    @apply from-gradient-end to-gradient-start;
  }
}

.filled,
.plain {
  &.icon-before,
  &.icon-after {
    @apply gap-16;
  }
}

.filled {
  @apply bg-white hover:bg-white/80;

  &[data-active='true'] {
    @apply bg-gradient-to-br from-gradient-start to-gradient-end text-white;
  }
}

.bordered {
  @apply bg-transparent text-white border border-greyblue-70 hover:border-greyblue-40;
}

.plain {
  @apply text-b14 text-white px-0 antialiased hover:text-greyblue-30;
}

/* roundness */
.roundness-3 {
  @apply rounded-3;
}

.roundness-4 {
  @apply rounded-4;
}

.roundness-8 {
  @apply rounded-8;
}

.roundness-full {
  @apply rounded-full;
}

/* theme */
.theme-light {
  &.filled {
    @apply bg-greyblue-95 text-white hover:bg-greyblue-20 hover:text-greyblue-95;

    &[data-active='true'] {
      @apply bg-gradient-to-br from-gradient-start to-gradient-end text-white;
    }
  }

  &.bordered {
    @apply text-greyblue-95 border-greyblue-20 hover:border-greyblue-40;
  }

  &.plain {
    @apply text-greyblue-95 hover:text-greyblue-30;
  }
}

.theme-medium {
  &.filled {
    @apply bg-greyblue-70 text-white;

    &:hover,
    &[data-hover='true'] {
      @apply bg-primary;
    }

    &[data-active='true'] {
      @apply bg-gradient-to-br from-gradient-start to-gradient-end text-white;
    }
  }
}

/* icon */
.icon {
  @apply w-18 h-18 shrink-0;
}

.icon-16 {
  @apply w-16 h-16;
}

.icon-18 {
  @apply w-18 h-18;
}

.icon-22 {
  @apply w-22 h-22;
}

.icon-before,
.icon-after {
  @apply gap-8;
}

/* Special button types */
.button-type-square {
  &.theme-dark {
    @apply bg-greyblue-85;
  }
}

.button-type-icon {
  &.bordered {
    &[data-active='true'] {
      @apply border-greyblue-5;

      &.theme-light {
        @apply border-greyblue-70;
      }
    }
  }
}
