.logo {
  position: relative;
  opacity: 0;
  animation: fade 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}

.logo-top {
  animation: bounce-top 1.75s infinite;
}

.logo-bottom {
  @apply absolute;

  bottom: calc(-100% + 14px);
  opacity: 0.7;
  animation: bounce-bottom 1.75s infinite;
}

.layer1 {
  animation: fade1 1.75s infinite;
}

.layer2 {
  animation: fade2 1.75s infinite;
}

@keyframes fade1 {
  0%,
  20%,
  80%,
  100% {
    opacity: 1;
  }

  40%,
  60% {
    opacity: 0;
  }
}

@keyframes fade2 {
  0%,
  20%,
  80%,
  100% {
    opacity: 0;
  }

  40%,
  60% {
    opacity: 1;
  }
}

@keyframes bounce-top {
  0%,
  20%,
  80%,
  100% {
    transform: translateY(0);
  }

  40%,
  60% {
    transform: translateY(-15px);
  }
}

@keyframes bounce-bottom {
  0%,
  20%,
  80%,
  100% {
    transform: translateY(-20px);
  }

  40%,
  60% {
    transform: translateY(-25px);
  }
}

.text {
  @apply text-b14 text-white mt-20;

  opacity: 0;
  animation: fade 1.5s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
