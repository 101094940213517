.label {
  @apply rounded-[6px] px-16 py-[6px] text-b14 inline-flex;
}

.theme-light {
  @apply bg-greyblue-20 text-greyblue-95;
}

.theme-dark {
  @apply bg-greyblue-70 text-white;
}
