.navigation-button {
  &:global(.swiper-button-disabled) {
    display: none;
  }
}

/* stylelint-disable function-no-unknown */
.swiper {
  --swiper-pagination-color: theme('colors.greyblue.70');
  --swiper-pagination-bullet-inactive-color: theme('colors.greyblue.20');
  --swiper-pagination-bullet-inactive-opacity: 1;
}
/* stylelint-enable function-no-unknown */

.news-item-text {
  h3 {
    @apply font-bold text-[1.25rem] leading-[1.5rem] mt-32 mb-16;
  }

  p + p {
    @apply mt-24;
  }

  ul,
  ol {
    @apply my-16 pl-32;

    li + li {
      @apply mt-8;
    }
  }

  ul {
    @apply list-disc;
  }
}
