.content {
  @apply z-10;

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}

.content[data-side='top'] {
  animation-name: slide-down-and-fade;
}

.content[data-side='right'] {
  animation-name: slide-left-and-fade;
}

.content[data-side='bottom'] {
  animation-name: slide-up-and-fade;
}

.content[data-side='left'] {
  animation-name: slide-right-and-fade;
}

@keyframes slide-up-and-fade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-right-and-fade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-down-and-fade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-left-and-fade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.arrow {
  @apply border-t-greyblue-85;

  position: relative;
  width: 0;
  height: 0;
  border-top-width: 10px;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;

  &::before {
    @apply border-t-greyblue-90;

    content: '';
    position: absolute;
    top: -12px;
    left: -10px;
    width: 0;
    height: 0;
    border-top-width: 10px;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }
}
