.collapsible-content {
  overflow: hidden;
}

.collapsible-content[data-state='open'] {
  animation: slide-down 250ms ease-out;
}

.collapsible-content[data-state='closed'] {
  animation: slide-up 250ms ease-out;
}

@keyframes slide-down {
  from {
    height: 0;
  }

  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes slide-up {
  from {
    height: var(--radix-collapsible-content-height);
  }

  to {
    height: 0;
  }
}
