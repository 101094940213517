.background {
  @apply rounded-8 bg-greyblue-5 shadow-[0_32px_64px_rgba(0,0,0,0.3)];
}

.button-primary,
.button-secondary {
  @apply flex cursor-pointer items-center justify-center rounded-full p-20 text-b16 text-[16px];
}

.button-primary {
  @apply text-white;

  background-image: linear-gradient(99deg, #00aded 5%, #6468e7 50%, #6468e7 100%);
  background-position-x: 10%;
  background-size: 130%;
  transition: background-position 250ms ease-in-out;

  &:hover {
    background-position-x: 100%;
  }
}

.button-secondary {
  @apply border border-greyblue-20 text-black transition-colors;

  &:hover {
    @apply border-greyblue-55;
  }
}

.button-link {
  @apply underline hover:text-black p-0;
}
